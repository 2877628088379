// import {axiosInstance as axios} from "../services/http.service";
import cookie from "js-cookie";
import axios from "axios";
import fileDownload from "js-file-download";

const fetcherGet = async (url) => {
    const token = await cookie.get('dakwah_fkam_token')
    const config = {
        headers: {
            "Authorization": `Bearer ${token}`
        },
        withCredentials: false
    }
    return axios.get(process.env.NEXT_PUBLIC_BACKEND_URL + url, config).then(res => res.data);
}

const fetcherPost = async (url, data, headers) => {
    const token = await cookie.get('dakwah_fkam_token')
    const config = {
        headers: {
            "Authorization": `Bearer ${token}`,
            ...headers,
        },
        withCredentials: false
    }
    return axios.post(process.env.NEXT_PUBLIC_BACKEND_URL + url, data, config).then(res => res.data);
}

const fetcherFormPost = async (url, data, headers) => {
    const token = await cookie.get('dakwah_fkam_token')
    const config = {
        headers: {
            "Content-Type": "multipart/form-data",
            "Authorization": `Bearer ${token}`,
            ...headers
        },
        withCredentials: false
    }
    return axios.post(process.env.NEXT_PUBLIC_BACKEND_URL + url, data, {...config}).then(res => res.data);
}

const fetcherFormPut = async (url, data, headers) => {
    const token = await cookie.get('dakwah_fkam_token')
    const config = {
        headers: {
            "Content-Type": "multipart/form-data",
            "Authorization": `Bearer ${token}`,
            ...headers
        },
        withCredentials: false
    }
    return axios.put(process.env.NEXT_PUBLIC_BACKEND_URL + url, data, {...config}).then(res => res.data);
}

const fetcherPut = async (url, data) => {
    const token = await cookie.get('dakwah_fkam_token')
    const config = {
        headers: {
            "Authorization": `Bearer ${token}`
        },
        withCredentials: false
    }
    return axios.put(process.env.NEXT_PUBLIC_BACKEND_URL + url, data, config).then(res => res.data);
}

const fetcherDelete = async (url) => {
    const token = await cookie.get('dakwah_fkam_token')
    const config = {
        headers: {
            "Authorization": `Bearer ${token}`
        },
        withCredentials: false
    }
    return axios.delete(process.env.NEXT_PUBLIC_BACKEND_URL + url, config).then(res => res.data);
}

const fetcherGetFile = async (url, filename) => {
    const token = await cookie.get('dakwah_fkam_token')
    const config = {
        headers: {
            "Authorization": `Bearer ${token}`
        },
        withCredentials: false,
        responseType: 'blob',
    }
    return axios.get(process.env.NEXT_PUBLIC_BACKEND_URL + url, config).then(res => fileDownload(res.data, filename));
}

export {
    fetcherPut,
    fetcherPost,
    fetcherGet,
    fetcherDelete,
    fetcherFormPost,
    fetcherFormPut,
    fetcherGetFile
}