import useSWR from 'swr'
import cookie from "js-cookie"
import jwt from 'jsonwebtoken'

const userFetcher = async () => {
    const token = cookie.get('dakwah_fkam_token')
    const SECRET_KEY = process.env.NEXT_PUBLIC_SECRET_KEY

    if (token) {
        // console.log("data", decoded)
        return jwt.verify(token, SECRET_KEY)
    }
    // not authorized
    const error = new Error('Not authorized!')
    error.status = 403
    throw error

}

export async function getUser(token) {
    const SECRET_KEY = process.env.NEXT_PUBLIC_SECRET_KEY
    return jwt.verify(token, SECRET_KEY)
}

export default function useUser() {
    const { data, mutate, error } = useSWR('get_token', userFetcher)

    const loading = !data && !error
    const loggedOut = error && error.status === 403

    return {
        loading,
        loggedOut,
        user:data,
        mutate,
    }
}