import Head from 'next/head'
import { AlertSection } from '../components/AlertSection'

export default function GuestLayout({children, ...props}) {
    return  (
        <div className={'bg-violet-100'}>
            <Head>
                <title>Dakwah - FKAM</title>
                <meta name="description" content="Aplikasi Dakwah FKAM" />
                <link rel="icon" href="/favicon.ico" />
            </Head>
            <AlertSection />
            {children}
        </div>
    )
}