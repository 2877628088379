import GuestLayout from "../../Layouts/GuestLayout";
import {useState} from "react";
import {useRouter} from "next/router";
import {fetcherPost} from "../../fetcher";
import useUser, {getUser} from "../../hooks/useUser";
import Swal from 'sweetalert2'
import cookie from "js-cookie";
import {Logo} from "../../components/Logo";
import nookies from "nookies";

export async function getServerSideProps(ctx) {
    const cookie = await nookies.get(ctx)
    let user = null

    if (cookie.dakwah_fkam_token) {
        try {
            const token = cookie.dakwah_fkam_token
            user = await getUser(token)
            return {
                redirect: {
                    destination: '/dashboard'
                }
            }
        } catch (err) {
            nookies.destroy(ctx, "dakwah_fkam_token")
        }
    }
    return { props: {user} }
}

export default function Login() {
    const {mutate} = useUser()
    const router = useRouter()
    const [loading, setLoading] = useState(false)
    const [form, setForm] = useState({
        email: '',
        password: ''
    })

    const handleLogin = async () => {
        if (loading === false) {
            setLoading(true)
            try {
                const {data} = await fetcherPost('/login', form);
                // setCookie(null, 'dakwah_fkam_token', data.token, {
                //     maxAge: 30 * 24 * 60 * 60,
                //     path: '/',
                //     httpOnly: true,
                // })
                cookie.set('dakwah_fkam_token', data.token, {
                    path: '/',
                    // httpOnly: true
                })
                await router.replace('/dashboard');
                setLoading(false)
            } catch (error) {
                setLoading(false)
                let message
                if (error.response?.status === 401) {
                    message = "Email/Password anda salah."
                } else {
                    message = error.message
                }
                await Swal.fire({
                    title: 'Gagal!',
                    text: message,
                    icon: '',
                    confirmButtonText: 'Ok'
                })
            }
        }
        await mutate
    }

    return  (
        <GuestLayout>
            <div className={'flex items-center justify-center h-screen w-screen p-5'}>
                <div className={'w-full md:w-1/5 flex flex-col items-center'}>
                    <Logo className={'text-4xl mb-5'} />
                    <div className={'rounded-lg bg-white p-5 shadow-lg shadow-violet-600/30'}>
                        <input onChange={(e)=> setForm({...form, email: e.target.value})} autoComplete={'false'} className={'input mb-3'} placeholder={'Email Address'} type={'email'} required={true}/>
                        <input onChange={(e)=> setForm({...form, password: e.target.value})} autoComplete={'false'} className={'input mb-3'} placeholder={'Your Password'} type={'password'} required={true}/>
                        <button onClick={()=> handleLogin()} className={`${loading ? 'cursor-wait' : 'cursor-pointer'} rounded px-3 py-2 text-white bg-primary-blue w-full hover:bg-opacity-90`}>
                            {loading ?
                            'Loading ...' : 'Sign In'
                            }
                        </button>
                    </div>
                    <div className={'text-center text-xs font-semibold text-gray-500 mt-3'}>&copy; 2021 - FKAM</div>
                </div>
            </div>
        </GuestLayout>
    )
}